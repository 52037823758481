import { useState, useRef, useEffect, useCallback } from "react";
import { WavRecorder } from "webm-to-wav-converter";
import { createUseStyles } from "react-jss";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../styles";
import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as MicIcon } from "../icons/mic.svg";
import { ReactComponent as RecordedIcon } from "../icons/recorded.svg";
import { Button } from "./Buttons/Button";
import { ThreeCountdown } from "./Countdowns/ThreeCountdown";
import { SixCountdown } from "./Countdowns/SixCountdown";
import { SoundLevel } from "./SoundLevel";
import { Circle } from "./Circle";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",

    "@media screen and (max-width: 560px)": {
      justifyContent: "space-between",
    },
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "auto 0",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
      gap: "40px",
      margin: "40px 0",
    },
  },
  topLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "50%",

    "@media screen and (max-width: 560px)": {
      width: "100%",
    },
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  video: {
    position: "fixed",
    top: "240px",
    left: "480px",
    width: "64px",
    height: "64px",

    "@media screen and (max-width: 560px)": {
      top: "calc(50% - 20px)",
      left: "calc(50% - 32px)",
    },

    "@media screen and (max-width: 385px)": {
      top: "calc(50% + 24px)",
    },
  },
  soundLevel: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    gap: "24px",
  },
  circleContainer: {},
});

export const Recording = ({ setRecordingId, setPage, setError }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const { t } = useTranslation();

  const stages = [
    {
      title: t("recording1Title"),
      video: "",
      buttonText: t("recording1Button"),
      videoComponent: <MicIcon />,
      animation: "/animations/circle_iframe_grey.json",
    },
    {
      title: t("recording2Title"),
      video: "",
      buttonText: t("recording2Button"),
      buttonColor: "#F20505",
      buttonHoverColor: "#AC0404",
      videoComponent: <ThreeCountdown />,
      animation: "/animations/circle_iframe_grey.json",
    },
    {
      title: t("recording3Title"),
      video: "",
      buttonText: t("recording3Button"),
      buttonColor: "#F20505",
      buttonHoverColor: "#AC0404",
      videoComponent: <SixCountdown />,
      animation: "/animations/circle_iframe.json",
    },
    {
      title: t("recording4Title"),
      video: "",
      buttonText: t("recording4Button"),
      videoComponent: <RecordedIcon />,
      animation: "/animations/circle_iframe_green.json",
    },
    {
      title: t("recording5Title"),
      video: "",
      buttonText: t("recording5Button"),
      videoComponent: <RecordedIcon />,
      animation: "/animations/circle_iframe_green.json",
    },
  ];

  const [stage, setStage] = useState(0);
  const [blob, setBlob] = useState();
  const [peak, setPeak] = useState(0);

  const setNewPeak = (value) => {
    if (peak < value && stage === 2) {
      setPeak(value);
    }
  };

  const ref = useRef();

  useEffect(() => {
    ref.current = new WavRecorder();
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const sendAudio = useCallback(
    async (form) => {
      const res = await axios.post(
        "https://masterhandlerapi.spectruminsights.net/api/recording/recording/",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const id = await res?.data?.recording_id;
      setRecordingId(id);
    },
    [setRecordingId, token]
  );

  useEffect(() => {
    const postAudio = async () => {
      if (blob) {
        const audioFile = new File([blob], "audio.wav", {
          type: "audio/wav",
        });
        const formData = new FormData();
        formData.append("sound_file", audioFile);
        await sendAudio(formData);
      }
    };

    postAudio();
  }, [blob, sendAudio]);

  const handleForwardClick = async () => {
    if (stage === 0) {
      setStage(1);
    } else if (stage === 1 || stage === 2) {
      setStage(0);
    } else if (stage === 3) {
      setBlob(await ref.current?.getBlob());
    }
  };

  const handleBackClick = () => {
    if (stage > 0) {
      setStage((current) => current - 1);
    } else {
      setPage("howTo3");
    }
  };

  useEffect(() => {
    let timer_1;
    let timer_2;
    if (stage === 1) {
      timer_1 = setTimeout(() => {
        setStage(2);
      }, 3000);
    }
    if (stage === 2) {
      ref.current.start();

      timer_2 = setTimeout(() => {
        setStage(3);
      }, 6000);
    }
    if (stage === 3) {
      ref.current.stop();
    }

    return () => {
      clearTimeout(timer_1);
      clearTimeout(timer_2);
    };
  }, [stage]);

  useEffect(() => {
    let interval_1;
    let interval_2;
    if (stage === 2) {
      interval_1 = setInterval(() => {
        if (peak < 20) {
          setError("quite");
        } else if (peak > 200) {
          setError("loud");
        }
      }, 2000);

      interval_2 = setInterval(() => {
        setPeak(0);
      }, [2005]);
    }

    return () => {
      clearInterval(interval_1);
      clearInterval(interval_2);
    };
  }, [peak, setError, stage]);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.topLeft}>
          <h2 className={commonClasses.title}>{stages[stage].title}</h2>
          <div className={classes.soundLevel}>
            <p>{t("recordingSoundLevel")}</p>
            <SoundLevel setPeak={setNewPeak} />
          </div>
        </div>
        <div className={classes.circleContainer}>
          <Circle animation={stages[stage].animation}>
            {stages[stage].videoComponent}
          </Circle>
        </div>
      </div>
      <div className={classes.bottom}>
        <button className={commonClasses.arrowButton} onClick={handleBackClick}>
          <ChevronLeftIcon />
        </button>
        <Button
          color={stages[stage].buttonColor}
          hoverColor={stages[stage].buttonHoverColor}
          onClick={handleForwardClick}
        >
          {stages[stage].buttonText}
        </Button>
      </div>
    </div>
  );
};
