import { createUseStyles } from "react-jss";

export const useCommonStyles = createUseStyles({
  title: {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "38px",

    "@media screen and (max-width: 560px)": {
      fontSize: "20px",
    },
  },
  arrowButton: {
    width: "32px",
    height: "32px",
    borderRadius: "47px",
    border: "none",
    backgroundColor: "#FCFCFD",
    cursor: "pointer",
    display: "flex",

    "&:hover": {
      backgroundColor: "#E6EFFB",
    },

    "@media screen and (max-width: 560px)": {
      marginRight: "100px",
    },
  },
  rightArrowButton: {
    width: "32px",
    height: "32px",
    borderRadius: "47px",
    border: "none",
    backgroundColor: "#0460D9",
    cursor: "pointer",
    display: "flex",

    "&:hover": {
      backgroundColor: "#03449A",
    },

    "&:disabled": {
      backgroundColor: "#D0D5DD",
    },
  },
  countdownNumber: {
    fontSize: "48px",
    color: "#101828",
    fontWeight: 700,
    textAlign: "center",
  },
  info: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkbox: {
    width: "24px",
    height: "24px",
    verticalAlign: "middle",
    marginRight: "8px",
    borderRadius: "4px",
    margin: 0,
  },
  label: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap",
  },
});
