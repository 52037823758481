import { useEffect, useState } from "react";
import axios from "axios";
import { createUseStyles } from "react-jss";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery } from "react-query";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../styles";
import { TextButton } from "./Buttons/TextButton";
import { Button } from "./Buttons/Button";
import { ReactComponent as DotInactiveIcon } from "../icons/dot_inactive.svg";
import { ReactComponent as DotActiveIcon } from "../icons/dot_active.svg";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  info: {
    backgroundColor: "#FFF",
    borderRadius: "30px",
    padding: "16px",
    height: "240px",

    "@media screen and (max-width: 560px)": {
      height: "max-content",
    },

    "& h4": {
      textAlign: "center",
    },
  },
  dots: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    margin: "12px auto 20px",

    "& svg": {
      cursor: "pointer",
    },
  },
  spinner: {
    margin: "auto",
  },
  button: {
    margin: "auto",
  },
});

export const Facts = ({ recordId, setViewStart, setResult, setPageTitle }) => {
  const classes = useStyles();
  const commomClasses = useCommonStyles();

  const [randomFacts, setRandomFacts] = useState([]);
  const [activeFact, setActiveFact] = useState(0);

  const { t } = useTranslation();

  const facts = [
    {
      title: t("fact1Title"),
      text: t("fact1Text"),
    },
    {
      title: t("fact2Title"),
      text: t("fact2Text"),
    },
    {
      title: t("fact3Title"),
      text: t("fact3Text"),
    },
    {
      title: t("fact4Title"),
      text: t("fact4Text"),
    },
    {
      title: t("fact5Title"),
      text: t("fact5Text"),
    },
    {
      title: t("fact6Title"),
      text: t("fact6Text"),
    },
    {
      title: t("fact7Title"),
      text: t("fact7Text"),
    },
    {
      title: t("fact8Title"),
      text: t("fact8Text"),
    },
    {
      title: t("fact9Title"),
      text: t("fact9Text"),
    },
    {
      title: t("fact10Title"),
      text: t("fact10Text"),
    },
    {
      title: t("fact11Title"),
      text: t("fact11Text"),
    },
    {
      title: t("fact12Title"),
      text: t("fact12Text"),
    },
    {
      title: t("fact13Title"),
      text: t("fact13Text"),
    },
    {
      title: t("fact14Title"),
      text: t("fact14Text"),
    },
    {
      title: t("fact15Title"),
      text: t("fact15Text"),
    },
    {
      title: t("fact16Title"),
      text: t("fact16Text"),
    },
  ];

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    const result = [];

    for (let i = 1; result.length < 3; i++) {
      const randomNumber = getRandomInt(0, facts.length - 1);
      if (!result.includes(randomNumber)) {
        result.push(randomNumber);
      }
    }
    setRandomFacts(result);
    setActiveFact(result[0]);
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const { data } = useQuery(
    `result-${recordId}`,
    async () => {
      const data = await axios(
        `https://masterhandlerapi.spectruminsights.net/api/recording/recording/${recordId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    {
      refetchInterval: (data) =>
        data?.data.status !== "results_ready" ? 2000 : false,
    }
  );

  useEffect(() => {
    if (data?.data.status === "results_ready") {
      setResult(data?.data.is_ill_score);
    }
  }, [data, setResult]);

  const factIndex = randomFacts.indexOf(activeFact);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (factIndex < 2) {
        setActiveFact(randomFacts[factIndex + 1]);
      }
    },
    onSwipedRight: () => {
      if (factIndex > 0) {
        setActiveFact(randomFacts[factIndex - 1]);
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <h2 className={commomClasses.title}>{t("factsTitle")}</h2>
        <TextButton onClick={() => setViewStart(true)}>
          {t("factsEnd")}
        </TextButton>
      </div>
      <div className={classes.info} {...handlers}>
        <h4>{facts[activeFact].title}</h4>
        <p>{facts[activeFact].text}</p>
      </div>
      <div className={classes.dots}>
        {randomFacts.map((number) => {
          if (number === activeFact) {
            return (
              <DotActiveIcon
                key={number}
                onClick={() => setActiveFact(number)}
              />
            );
          } else {
            return (
              <DotInactiveIcon
                key={number}
                onClick={() => setActiveFact(number)}
              />
            );
          }
        })}
      </div>
      {data?.data.is_ill_score ? (
        <Button
          style={{ margin: "auto" }}
          onClick={() => setPageTitle("result")}
        >
          {t("factsSeeResults")}
        </Button>
      ) : (
        <ClipLoader color="#0460D9" loading className={classes.spinner} />
      )}
      <TextButton
        style={{ marginTop: "32px" }}
        onClick={() => setViewStart(true)}
      >
        {t("factsEnd")}
      </TextButton>
    </div>
  );
};
