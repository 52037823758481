import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { HowToContent } from "./Content";
import { PagesSwitcher } from "../PagesSwitcher";
import { HowToTop } from "./Top";

const useStyles = createUseStyles({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
});

export const HowToFirst = ({ setPage }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <HowToTop setPage={setPage} />
      <HowToContent title={t("howToFirstTitle")} text={t("howToFirstText")}>
        <img
          className={classes.image}
          src="/images/aged_man.jpeg"
          alt="aged man"
        />
      </HowToContent>
      <PagesSwitcher previousPage="rules" nextPage="howTo2" setPage={setPage} />
    </>
  );
};
