import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { TextButton } from "./Buttons/TextButton";
import { Button } from "./Buttons/Button";
import { useCommonStyles } from "../styles";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ReactComponent as WarningIcon } from "../icons/warning.svg";

const useStyles = createUseStyles({
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  result: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "24px",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
    },
  },
  description: {
    lineHeight: "24px",
    width: "55%",
    "@media screen and (max-width: 560px)": {
      width: "100%",
    },
  },
  resultPoints: {
    color: ({ color }) => color,
    textAlign: "center",
    width: "50%",
  },
  resultTitle: {
    fontSize: ({ fontSize }) => fontSize,
    margin: 0,
  },
  resultNumbers: {
    fontSize: "72px",
    fontWeight: 700,
    margin: 0,
  },
  warning: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    marginTop: "32px",

    "& p": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",

    "& p": {
      fontSize: "12px",
      lineHeight: "18px",
      width: "230px",

      "@media screen and (max-width: 560px)": {
        width: "100%",
      },
    },
  },
  bottomActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "@media screen and (max-width: 560px)": {
      flexDirection: "column",
      gap: "64px",
      marginTop: "24px",
    },
  },
  stores: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  playMarket: {
    width: "108px",
    height: "32px",
  },
  appStore: {
    width: "96px",
    height: "32px",
  },
});

export const Result = ({ result, setPageTitle, setViewStart }) => {
  const roundedResult = Math.round(result);

  const [resultGroup, setResultGroup] = useState();

  const { t } = useTranslation();

  const results = {
    good: {
      title: t("resultsGoodTitle"),
      description: t("resultsGoodText"),
      color: "#04C57E",
      resultFontSize: "36px",
    },
    color: "#04C57E",
    middle: {
      title: t("resultsMiddleTitle"),
      description: t("resultsMiddleText"),
      color: "#F29D1D",
      resultFonrSize: "18px",
    },
    bad: {
      title: t("resultsBadTitle"),
      description: t("resultsBadText"),
      color: "#F20505",
      resultFontSize: "24px",
    },
  };

  useEffect(() => {
    if (roundedResult <= 2) {
      setResultGroup("bad");
    } else if (roundedResult <= 4) {
      setResultGroup("middle");
    } else {
      setResultGroup("good");
    }
  }, [roundedResult]);

  const classes = useStyles({
    color: results[resultGroup]?.color,
    fontSize: results[resultGroup]?.resultFontSize,
  });
  const commonClasses = useCommonStyles();

  const queryParams = new URLSearchParams(window.location.search);
  const contactUrl = queryParams.get("contactUrl");

  return (
    <div>
      <div className={classes.top}>
        <TextButton onClick={() => setViewStart(true)}>
          {t("resultsStartAgain")}
        </TextButton>
        <h2 className={commonClasses.title}>{t("resultsResult")}</h2>
        <button
          className={commonClasses.info}
          onClick={() => setPageTitle("resultBD")}
        >
          <InfoIcon />
        </button>
      </div>
      <div className={classes.result}>
        <p className={classes.description}>
          {results[resultGroup]?.description}
        </p>
        <div className={classes.resultPoints}>
          <p className={classes.resultTitle}>{results[resultGroup]?.title}</p>
          <p className={classes.resultNumbers}>{`${roundedResult}/10`}</p>
        </div>
      </div>
      <div className={classes.warning}>
        <WarningIcon />
        <p>{t("results1")}</p>
      </div>
      <div className={classes.bottom}>
        <div>
          <p>{t("results2")}</p>
        </div>
        <div className={classes.bottomActions}>
          <div className={classes.stores}>
            <a
              href="https://play.google.com/store/apps/details?id=health.vividmind.memeoHealth"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/play-market.png"
                alt="play market"
                className={classes.playMarket}
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/memeo-health/id6470153707"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/app-store.png"
                alt="app store"
                className={classes.appStore}
              />
            </a>
          </div>
          {contactUrl ? (
            <a href={contactUrl} target="_blank" rel="noreferrer">
              <Button>{t("resultsContact")}</Button>
            </a>
          ) : (
            <Button>{t("resultsContact")}</Button>
          )}
        </div>
      </div>
    </div>
  );
};
